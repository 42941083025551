<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
            {{
              $t("message.edit_m", {
                m: $t("message.district"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form ref="form" status-icon :model="form" :rules="rules">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.name") }}
                </span>
                    <el-form-item
                        prop="name"
                        class="label_mini"
                    >
                        <crm-input
                            :size="'medium'"
                            :inputValue="form.name"
                            v-model="form.name"
                            :placeholder="$t('message.name')"
                            :class="mode ? 'input__day' : 'input__night'"
                        ></crm-input>
                    </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="12">
              <span class="input--label d-block mb-3" :class="mode ? 'text__day2' : 'text__night2'">
                {{ $t("message.region") }}
              </span>

              <select-region
                :id="form.region_id"
                v-model="form.region_id"
                :size="'medium'"
              >
              </select-region>
            </el-col>
            <!-- end col -->
          </el-row>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
/**
 * Drawer chaqirish kerak drawerni yopish uchun
 */
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
export default {
    mixins: [form, drawer, show],
    name: "NationController",
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "district/rules",
            model: "district/model",
            columns: "district/columns",
            mode: "MODE"
        }),
    },
    methods: {
        ...mapActions({
            update: "district/update",
            show: "district/show",
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
